import {logInformationToElasticsearch} from './elasticsearchService';
const { REACT_APP_ELASTICSEARCH_ENABLED } = process.env;

export default async function logInfoMessage(infoMessage){
  try {
    console.log(infoMessage);
    if(REACT_APP_ELASTICSEARCH_ENABLED === '1')
    {
    const res = await logInformationToElasticsearch('securityinfo',infoMessage,'info',200);
    return res;
    }
    else{
      console.log('[logInfoMessage - logger.js] - Logging for Elastic is disabled.');
    }
  } catch (error) {
    console.log(error);
  }
};
const logWarningMessage = async (warningMessage) => {
  try {
    console.log(warningMessage);
  } catch (error) {
    console.log(error);
  }
};
const logErrorMessage = async (errorMessage) => {
  try {
    console.log(errorMessage);
  } catch (error) {
    console.log(error);
  }
};
const logSuccessMessage = async (successMessage) => {
    try {
        console.log(successMessage);
    } catch (error) {
        console.log(error);
    }
  };

export { 
  logInfoMessage,
  logErrorMessage,
  logSuccessMessage,
  logWarningMessage 
};