const axios = require("axios");
const { DateTime } = require('luxon');
const { 
  REACT_APP_ELASTICSEARCH_URL,
  REACT_APP_GEOLOCATION_WEB_SERVICE_URL
} = process.env;


const getUserLocationData = async () => {
  try {
      const response = await axios.get(REACT_APP_GEOLOCATION_WEB_SERVICE_URL);   
      return response;   
  } catch (error) {
    console.log(error);
  }
  
//   const res = await axios.get(REACT_APP_GEOLOCATION_WEB_SERVICE_URL);
//   console.log(res.data);
//   return res.data;
}

export default async function logInformationToElasticsearch(index,logMessage, messageType, messageStatusCode){
  
    let indexName = index;
    indexName = 'securityinfo';
    let url =`${REACT_APP_ELASTICSEARCH_URL}/${indexName}/_doc`; 
  
    const config = {
      crossdomain: true,
      headers: {
          'Content-Type': 'application/json'
      }
    };

    //get current local time and add it to the request
    let currentDate = DateTime.local();
    currentDate.setZone('Europe/Vienna');
    currentDate.setLocale('de-AT');
    const currentISODate = currentDate.toISO();
    const userLocationInformation = await getUserLocationData();
    //get current ip address of the user and add it to the request

    const bodyParameters =  {
      "@timestamp": `${currentISODate}`,
      currentdate: `${currentISODate}`,
      message : `${logMessage}`,
      message_type: `${messageType}`,
      message_status_code:`${messageStatusCode}`,
      location: { 
        lat: 41.12,
        lon: -71.34
      },
      user_destination :
      {
          ip : `${userLocationInformation.data.IPv4}`,
          port: 88,
          country_code: `${userLocationInformation.data.country_code}`,
          country_name: `${userLocationInformation.data.country_name}`,
          latitude: `${userLocationInformation.data.latitude}`,
          longitude: `${userLocationInformation.data.longitude}`,
          postal: `${userLocationInformation.data.postal}`,
          state: `${userLocationInformation.data.state}`
      },
      geo_location : {
        type:"FeatureCollection",
        features:[
           {
              type:"Feature",
              geometry:{
                 type:"geo_point",
                 coordinates:[
                    15.4,
                    47
                 ]
              },
              properties:{
                 "prop0":"value0"
              }
           }
        ]
     }
   }

    try {     
     
      const res =  await axios.post(url, bodyParameters,config);
      return res;

    } 
    catch (error) {
    //   logErrorMessage(error.message);
      console.log('[Error saving to Elasticsearch Index - elasticsearchService.js(93)]' + error);
      throw error;
    }
}


export { 
  logInformationToElasticsearch,
}