// import React, { useState, useEffect } from "react";
import React, { useEffect } from "react";
import './App.css';
import queryString from 'query-string';
import { OS } from './utils/platform';
import co2wizardLogo from './assets/images/co2wizardLogo.png';
import { logInfoMessage } from './logging/logger';
import {ResizeObserver as ResizeObserverPolyfill} from '@juggle/resize-observer';

const  {  
  REACT_APP_REDIRECTION_URL_TYPE_FOLDER,
  REACT_APP_REDIRECTION_URL_TYPE_INSTALLATION
} = process.env

if (typeof window !== 'undefined') {
  window.ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;
}

function RedirectToURL(type){
     // Redirect to pre-defined URL
     console.log('[RedirectToURL] Trying to redirect to ...')
     if(type==='folder')
     {
          console.log('[RedirectToURL] Redirect to folder info...')
          window.location.href = REACT_APP_REDIRECTION_URL_TYPE_FOLDER;
     }
     else if(type==='installation')
     {
          console.log('[RedirectToURL] Redirect to installation info...')
          window.location.href = REACT_APP_REDIRECTION_URL_TYPE_INSTALLATION;
     }
     else{
      console.log('[RedirectToURL] No Redirection Type found. Abort Redirection.');
     }

}

function Sleep(milliseconds) {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
 }

function App() {

  useEffect(() => { 
    //Load the type --> Needed for Redireection
    let { type } = queryString.parse(window.location.search); 
    console.log('[useEffect] Trying to load info for: '+ type);

    async function logInfoMessageToElastic(type){
        try {
          const os = OS(window);
          await logInfoMessage(os);
          await Sleep(2000);     
          RedirectToURL(type);
        } catch (error) {
          console.log(error);
          RedirectToURL(type);
        }
      
    }
    
    logInfoMessageToElastic(type);

  }, []);

  return (
    <div className="App">      
      <header className="App-header">     
        <div style={{marginTop:'10px'}} >     
            <div className="container">           
              <img src={co2wizardLogo} className='icn-spinner' style={{ width:'150px'}} alt="" />
              <p>loading...</p>   
            </div> 
        </div>
      </header>
      </div>   
  );
}

export default App;
